.heading3 {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4px;
}

.leave-review-main-container {
  height: calc(100% - 30px);
  overflow: auto;
  width: 100%;
}

.holiday-list-sub-container {
  margin: 0;
  display: flex;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  justify-content: center;
}

/* Boilerplate stuff */

.holiday-list-container {
  height: 100%;
  display: flex;
  justify-content: center;
}

/*table*/

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.table-wrapper {
  height: 100%;
  width: 100%;
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

.holiday-list-container {
  height: 100%;
  display: flex;
  justify-content: center;
}

/*table*/

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.table-wrapper {
  height: 100%;
  width: 100%;
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}

.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}

.student-profile .card .card-header .profile_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 10px auto;
  border: 10px solid #ccc;
  border-radius: 50%;
}

.student-profile .card h3 {
  font-size: 20px;
  font-weight: 700;
}

.student-profile .card p {
  font-size: 16px;
  color: #000;
}

.student-profile .table th,
.student-profile .table td {
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
}

.leave-review-main-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.leave-review-main-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */

.leave-review-main-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */

.leave-review-main-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.approve-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  border: 1px solid var(--successColor);
  padding: 4px;
  border-radius: 10px;
  margin-right: 6px;
  gap: 2px;
  box-shadow: inset 2px 2px 2px #5dc529;
}

.reject-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  border: 1px solid var(--dangerColor);
  padding: 4px;
  border-radius: 10px;
  margin-right: 6px;
  gap: 2px;
  box-shadow: inset 2px 2px 2px #de7f74;
}

.pending-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  border: 1px solid var(--secondaryColor);
  padding: 4px;
  border-radius: 10px;
  margin-right: 6px;
  gap: 2px;
  box-shadow: inset 2px 2px 2px #e8ca89;
}

.leave-review-sub-container {
  display: flex;
  width: 100%
}

.leave-review-field-container {
  width: 35% !important;
  border: 1px solid #eee;
  padding: 3% !important;
  text-align: left !important;
  font-weight: 600
}

.leave-review-field-container1 {
  width: 5%;
  padding: 3%;
}

.leave-review-field-container2 {
  border: 1px solid #eee;
  width: 60%;
  padding: 3%
}

.leave-review-field-container3 {
  border: 1px solid #eee;
  width: 25%;
  padding: 3%;
  font-weight: 600;
}

.leave-review-field-container4 {
  border: 1px solid #eee;
  width: 70%;
  padding: 3%;
}

.leave-review-field-container5 {
  border: 1px solid #eee;
  width: 70%;
  display: flex;
}





.leave-review-data-container {
  border: '1px solid #eee';
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.leave-review-left-data-container {
  display: flex;
  flex-direction: column;
  flex-basis: 40%
}

.leave-review-right-data-container {
  display: flex;
  flex-direction: column;
  flex-basis: 60%
}

.leave-review-download-button-container {
  border-radius: 20px !important;
  /* margin-top: 15px !important; */
  padding: 0px !important;
  background-color: var(--primaryColor) !important;
  /* color: var(--white) !important; */
}

.leave-review-field-container-file {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%
}

.leave-review-field-sub-container-file {
  display: flex;
  align-items: center;
  height: 100%
}

@media screen and (min-width:300px) and (max-width:426px) {

  .heading3 h2 {
    font-size: 18px !important;
  }



  .leave-review-data-container {
    font-size: 12px !important;
  }

  .card-content-container {
    padding: 2px 4px 3px 3px !important;
  }

  .leave-review-field-container {
    text-align: left;
  }

  .leave-review-data-container {
    flex-direction: column !important;
  }

  .leave-review-left-data-container {
    flex-basis: 100% !important
  }

  .leave-review-right-data-container {
    flex-basis: 100% !important
  }

  .leave-review-field-container3 {
    width: 38% !important;
  }

  .leave-review-field-container4 {
    width: 57% !important;
  }

  .leave-review-field-container5 {
    width: 57% !important;
  }

  .reject-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }

  .approve-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }

  .heading1 h2 {
    font-size: 15px;
  }

  .heading1 {
    height: 7% !important;
  }

  .sub-container {
    height: 93% !important;
  }
}

@media screen and (min-width:377px) and (max-width:426px) {
  .leave-review-data-container {
    font-size: 12px !important;
  }

  .card-content-container {
    padding: 2px 4px 3px 3px !important;
  }

  .leave-review-field-container {
    text-align: left;
  }

  .leave-review-data-container {
    flex-direction: column !important;
  }

  .leave-review-left-data-container {
    flex-basis: 100% !important
  }

  .leave-review-right-data-container {
    flex-basis: 100% !important
  }

  .leave-review-field-container3 {
    width: 35% !important;
  }

  .leave-review-field-container4 {
    width: 60% !important;
  }

  .leave-review-field-container5 {
    width: 60% !important;
  }

  .reject-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }

  .approve-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }
}

@media screen and (min-width:427px) and (max-width:600px) {
  .heading3 h2 {
    font-size: 18px !important;
  }


  .leave-review-data-container {
    font-size: 15px !important;
  }

  .card-content-container {
    padding: 2px 4px 3px 3px !important;
  }

  .leave-review-field-container {
    text-align: left;
  }

  .leave-review-data-container {
    flex-direction: column !important;
  }

  .leave-review-left-data-container {
    flex-basis: 100% !important
  }

  .leave-review-right-data-container {
    flex-basis: 100% !important
  }

  .leave-review-field-container3 {
    width: 35% !important;
  }

  .leave-review-field-container4 {
    width: 60% !important;
  }

  .leave-review-field-container5 {
    width: 60% !important;
  }

  .reject-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }

  .approve-status-container {
    font-size: 10px !important;
    margin-top: 0px !important;
    padding: 3px !important;
    margin-right: 2px !important;
  }

  .heading1 {
    height: 7% !important;
  }

  .sub-container {
    height: 93% !important;
  }
}

@media screen and (min-width:600px) and (max-width:900px) {
  .leave-review-data-container {
    flex-direction: column !important;
  }


  .leave-review-field-container3 {
    width: 40% !important;
  }

  .leave-review-field-container1 {
    width: 5% !important;
  }

  .leave-review-field-container5 {
    width: 70% !important;
  }


}

@media screen and (min-width:901px) and (max-width:1024px) {
  .leave-review-data-container {
    flex-direction: column !important;
  }

  .heading h2 {
    font-size: 20px;
  }

  .leave-review-data-container {
    flex-direction: row !important;
  }

  .leave-review-field-container3 {
    width: 35% !important;
  }

  .leave-review-field-container {
    width: 35% !important;
  }

  .leave-review-field-container1 {
    width: 5% !important;
  }

  .leave-review-field-container5 {
    width: 60% !important;
  }

  .leave-review-field-container4 {
    width: 60% !important;
  }
}