.drawer-container {
    height: inherit !important;
    width: 300px !important;
    overflow: auto !important;
}

.drawer-header {
    height: 63px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

.drawer-body {
    height: calc(100% - 63px) !important;
}

.drawer-close-icon {
    color: var(--primaryColor);
    width: 100%;
    cursor: pointer;
    margin-right: 3px;
}

.drawer-header div {
    color: var(--primaryColor);
    padding-left: 20px !important;
    width: 100%;
}


@media screen and (min-width:529px) and (max-width:600px) {
    .drawer-header {
        height: 48px !important;
    }

    .drawer-body {
        height: calc(100% - 48px) !important;
    }
}

@media screen and (min-width:300px) and (max-width:528px) {
    .drawer-header {
        height: 56px !important;
    }

    .drawer-body {
        height: calc(100% - 56px) !important;
    }
}

@media screen and (min-width:300px) and (max-width:375px) {
    .drawer-container {
        width: 270px !important;
    }
}