.team-members-main-container {
    height: 100%;
}

.team-members-sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

.team-members-list-container {
    height: calc(100% - 30px) !important;
    overflow: auto;
}

.team-members-list-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.team-members-list-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.team-members-list-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.team-members-list-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.list-item {
    background-color: var(--primaryColor);
    color: white;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: 0.3s;
}

.list>ol {
    /* background-color: var(--primaryColor);
    color: white;
    border-radius: 3px; */
    margin-bottom: 0px;
    /* transition: 0.3s; */
}

.list {
    flex-direction: column;
    padding-right: 8px;
}

.list li {
    padding: 10px;
}

.list-item:last-child {
    margin-bottom: 0px;
}