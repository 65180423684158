.leave-details-sub-container {
    height: 100%;
}

.leave-details-close-button {
    position: absolute;
    text-align: right;
    right: 0;
    top: 0;
    padding: 2px;
    opacity: 1
}



.leave-details-heading {
    color: var(--primaryColor);


}

.leave-containers {
    font-size: 14px
}

.leave-containers-label {
    margin-top: 1%;

}

.leave-details-break-main-container {
    display: flex;
    gap: 50%;
    flex-wrap: wrap;
}

.leave-details-break-sub-container {
    margin-top: 15px;
}

.leave-details-break-container {
    margin-top: 5px;
}

.leave-balance-show-container {
    padding-right: 0px;
}

.leave-containers label {
    font-weight: 700;
}

.leave-details-balance-container {
    width: 190px
}

@media screen and (min-width:319px) and (max-width:478px) {
    .leave-details-heading {
        font-size: 14px;
    }

    .leave-containers label {
        font-size: 12px;
    }

    .leave-containers div {
        font-size: 10px;
    }

    .leave-details-balance-container {
        width: 145px
    }

}