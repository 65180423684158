.leave-sub-container {
    border-bottom: 1px solid;
}

.leave-heading-container {
    height: 10%;
}

.leave-application-heading-container {
    height: 35px;
}

.leave-heading-sub-container {
    height: 35px;
}

.leave-container {
    height: calc(100% - 35px);
    padding-top: 12px;
}

.leave-main-container {
    border: 1px solid;
    border-radius: 5px;
    height: 100%;
}

.user-leave-details-main-container {
    overflow: auto;
    height: calc(100% - 30px);
}

.user-leave-details-main-container::-webkit-scrollbar {
    width: 5px;
}



/* Track */
.user-leave-details-main-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle */
.user-leave-details-main-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle on hover */
.user-leave-details-main-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}

.sub-body-container {
    width: 100%;
    height: calc(100% - 35px);
}

.leave-table-sub-container {
    display: flex;
    width: 100%;
    padding: 1% 0% 1% 0%;
    cursor: pointer;
    justify-content: space-evenly;
    flex-wrap: wrap;
}


.leave-type-container {
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leave-break-sub-container {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: auto
}

.leave-details-container {
    width: 36%;
}

.leave-details-button {
    border: 1px solid !important;
    background-color: var(--primaryColor) !important;
    color: var(--tertiarycolor) !important;

}

.leave-type-sub-container label {
    margin-top: 1%;
}

.leave-button-group {
    height: 5%;
    text-align: right;
    padding-right: 2%
}

.leave-button-group1 {
    height: 5%;
    text-align: left;
    padding-left: 1%
}

.leave-details-heading-container {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(156, 152, 152);
    background-color: var(--primaryColor);
    color: var(--tertiarycolor);
    position: sticky;
    position: -webkit-sticky !important;
    font-size: 100%;
}

.user-leave-details-sub-container {
    height: 100%;
    width: 100%;
}

.leave-type-head-container {
    width: 18%;
    text-align: center;
    font-weight: 700;
}

.leave-details-head-container {
    width: 82%;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}


.leave-break-sub-container label {
    font-weight: 600;
}

.leave-break-sub-container1 label {
    font-weight: 600;
}

.leave-break-sub-container1 div {
    font-size: 15px;
}

.leave-break-sub-container div {
    font-size: 15px;
}

@media (max-width:2440px) {
    .leave-details-container {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.leave-table-type-container {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leave-table-details-container {
    width: 82%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leave-table-details-sub-container {
    width: 100%;
    display: flex
}

.balance-container {
    width: 210px;
}

@media screen and (min-width:300px) and (max-width:478px) {
    .leave-table-type-container {
        font-size: 9px !important;
    }

    .leave-table-details-sub-container div {
        font-size: 9px;
    }

    .balance-container {
        width: 140px;
    }

    .leave-balance-show-container {
        padding: 0px 0px 0px 2px;
    }

    .button-text-container {
        font-size: 1px;
        margin-left: 2px;
    }

    .leave-details-button {
        height: 22px;
        /* margin-left: 2px; */
    }

    .leave-details-heading-container {
        font-size: 12px;
    }
}


@media screen and (min-width:300px) and (max-width:426px) {
    .heading4 h2 {
        font-size: 18px;
    }

    .circular-add-button {
        font-size: 160% !important;
    }

    .back-button {
        font-size: 100% !important;
    }

    .employee-back-button-heading-container h2 {
        font-size: 15px;
    }

}



@media screen and (min-width:427px) and (max-width:600px) {
    .heading4 h2 {
        font-size: 18px;
    }

    .employee-back-button-heading-container h2 {
        font-size: 18px;
    }

    .circular-add-button {
        font-size: 180% !important;
    }

    .back-button {
        font-size: 120% !important;
    }
}