.task-reason-main-container {
    height: fit-content !important;
}

.label-container {
    font-size: var(--extra-small-font) !important;
    font-weight: 600 !important;
    padding: 3px !important;
}

.reason-field-container {
    max-height: 300px;
    overflow: auto;
    padding-right: 3px;
}

.preav-status-container,
.present-status-container {
    font-size: var(--more-extra-small-font) !important;
    border: 1px solid var(--secondaryColor);
    border-radius: 3px;
    padding: 0px 1px;
    background-color: var(--secondaryColor);
    color: var(--tertiarycolor);
}

.preav-status-container {
    margin-left: 5px !important;
}

.arrow-button {
    margin-top: 2px !important;
}

.status-main-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-basis: 200px !important;
}

.status-sub-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-width {
    flex-basis: 50% !important;
}