/* Sidebar */

.sidebar-drawer-container {
    height: 100vh;
    overflow-y: hidden;
}

.drawer-heading {
    height: 64px !important;
}

.sidebar {
    display: flex;
    flex-direction: column;
    background: rgb(234, 239, 244);
    color: var(--primaryColor);
    top: 0;
    border-right: 1px solid #eee;
    border-left: var(--primaryColor1);
    /* background-color: aqua; */
    height: 100vh;
}


.top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
}

/* .logo {
    font-size: 18px;
    line-height: 0;
  } */
.bars {
    width: 30px;
    padding: 12px 3px;
    cursor: pointer;
    height: 74px;
}

.bars1 {
    width: 30px;
    padding: 14px 3px;
    cursor: pointer;
    height: 74px;
}

.hide {
    display: none;
}

.search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
}

.search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
    color: white;
}

.routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    justify-content: space-evenly;
    overflow-y: hidden;
    overflow-x: hidden;
}

.routes:hover {
    overflow-y: auto;
}

.routes::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.routes::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle */
.routes::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle on hover */
.routes::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}

.icon {
    margin-top: -2px;
}

.link {
    display: flex;
    color: var(--primaryColor);
    gap: 10px;
    padding: 5px 10px;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
}

.link:hover {
    border-right: 4px solid var(--primaryColor);
    background: var(--border-color);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
}

.active {
    border-right: 4px solid var(--primaryColor);
    background: var(--border-color);
    text-decoration: none;
}

.link-text {
    white-space: nowrap;
    font-size: 15px;
    padding-top: 6px;
}

.menu {
    display: flex;

    padding: 8px 12px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
    gap: 5px;
}

.menu-item {
    display: flex;
    gap: 10px;
}

.menu-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

/* .menu-container .link {
    padding-left: 20px;
  } */

/* #motion-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
} */

.sidenav-section-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 82%;
}

.profile-menu {
    margin-left: 40px;
    top: -20px !important;
}

.profile-menu-container {
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #EAEFF4;
}

.opened {
    width: 200px;
    /* height: 200px; */
    transition: 'width' 0.5s ease-in-out;
}

.closed {
    width: 45px;
    transition: 'width' 0.5s ease-in-out;
}

.sidebar-head {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    height: 100%;
    /* border: 1px solid red; */

}

.sidebar-list-conatiner {
    height: calc(100% - 64px) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column
}



.sidebar-drawer-container:hover {
    overflow-y: auto;
}

.sidebar-drawer-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.sidebar-drawer-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle */
.sidebar-drawer-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle on hover */
.sidebar-drawer-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}



@media (max-width: 575px) {


    .menu-container {
        margin-left: 0px;
    }
}

@media (max-width: 599px) {
    .drawer-heading {
        height: 48px !important;
    }

    .sidebar-list-conatiner {
        height: calc(100% - 48px) !important;
    }
}