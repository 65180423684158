.approve-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    border: 1px solid var(--successColor);
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap: 2px
}

.reject-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    border: 1px solid var(--dangerColor);
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap: 2px
}

.pending-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /* gap: 2px */
}

.cancel-button-container {
    display: flex;
    height: 22px;
}

.cancel-button-container button {
    background-color: var(--dangerColor);
    color: var(--tertiarycolor);
    border: 1px solid var(--tertiarycolor);

}

.cancel-button-container button :hover {
    text-decoration: none;
}

.tooltip {
    background-color: brown;
}

.button-height {
    height: 24px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}