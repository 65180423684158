.attendance-details-item-container {
    display: flex;
    padding: 5px 0px 5px 0px;
}

.attendance-details-main-container {

    position: relative;
}

.attendance-details-main-container .attendance-details-header {
    font-size: 90%;
    color: var(--primaryColor);
}

.attendance-details-sub-container {
    margin-top: 3%;
    color: var(--primaryColor1)
}

.attendance-details-close-button {
    color: var(--dangerColor);
    text-align: right;
    font-size: 20px;
    padding: 3px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
}

.attendance-details-close-button:hover {
    opacity: 1;
}