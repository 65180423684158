.attendance-date-container {
  width: 100%;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-top {
  display: flex;
  width: 100%;
}

.custom-header {
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
}

.heading2 .MuiInputBase-input {
  height: 10px !important
}

.attendance-list-container table td {
  padding: 4px !important;
}

.table-head {
  padding: 3%
}

.table-row-head {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 10px !important;
  width: 100%;
}

.table-row-head1 {
  /* padding: 10px !important */
  width: 13% !important;
}

.width20 {
  width: 20%;
}

.width12 {
  width: 12%;
}

.width25 {
  width: 25%;
}

.width25 {
  width: 25%;
}

.width16 {
  width: 16%;
}

.width10 {
  width: 10%;
}

.width60 {
  width: 60%;
}



.attendance-dates-container {
  /* width: 100%; */
  height: 49px;
}

.download-button-container {
  border-radius: 20px !important;
  height: 24px;
}

.heading2 {
  height: 11%;
  padding: 10px 2px 2px 15px;
}

.attendance-sub-container {
  overflow: auto;
  height: 100%;
  padding-top: 0px;
}

.attendance-sub-container .table button:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: 0.5s;
}

.attendance-sub-container .table button {
  color: var(--primaryColor);
  background-color: #fff;
  transition: 0.5s;
}

.date-range-sub-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 100%;
}


.date-range-sub-container .MuiInputBase-root {
  height: 40px;
  overflow-y: hidden;
}

.date-range-sub-container .MuiInputBase-input {
  font-size: 12px !important;
  padding: 0px 3px !important;
  text-align: center;
}


.header-sub-container .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center !important;
  font-size: 14px !important;
}

.header-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 60%;
  flex-basis: 300px;
}

.date-sub-container {
  height: 100%;
}

.download-button-main-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}

.date-picker-container {
  width: 90px !important;
}





@media screen and (min-width:300px) and (max-width:421px) {
  .date-range-sub-container {
    gap: 14px;
  }



  .download-button-main-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
  }

}

@media screen and (min-width:461px) and (max-width:523px) {

  .header-sub-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 250px !important;
  }



  .date-range-sub-container {
    justify-content: flex-end;
  }

}

@media screen and (min-width:300px) and (max-width:460px) {
  .menu-item-container {
    min-height: 40px !important;
  }

  .date-picker-container .MuiInputBase-root {
    height: 25px !important;
  }

  .date-picker-container .MuiOutlinedInput-input {
    font-size: 12px !important;
  }

  .date-picker-container .Mui-readOnly {
    text-align: center;
    padding: 0px 0px;
  }

  .date-picker-container .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px;
    text-align: center;
    padding: 0px;
  }

  .date-picker-container {
    height: 25px !important;
  }



  .header-sub-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 100px !important;
  }

  .date-range-sub-container {
    justify-content: flex-end;
  }

  .heading h2 {
    font-size: 16px !important;
  }

}

@media screen and (min-width:524px) and (max-width:600px) {
  .header-sub-container {
    /* flex-direction: column; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

}

@media screen and (min-width:600px) and (max-width:773px) {
  .date-picker-container .MuiInputBase-root {
    height: 25px !important;
  }

  .date-picker-container .MuiOutlinedInput-input {
    font-size: 12px !important;
  }

  .date-picker-container .Mui-readOnly {
    text-align: center;
    padding: 0px 0px;
  }

  .header-sub-container .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    border: 1px solid red !important;
  }


  .header-sub-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 100px !important;
  }

  .date-range-sub-container {
    justify-content: flex-end;
  }


}

@media screen and (min-width:773px) and (max-width:1030px) {

  .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: 170px !important;
  }

}