.wraper {
    height: 100%;
}

/* .wraper .ag-header-cell-label {
    justify-content: center;
} */

/* .wraper .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
} */

input {
    padding: 20px 10px
}


.admin-payroll-heading {
    height: '35px';
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-payroll-sub-container {
    height: calc(100% - 35px);
    padding-top: 4px;
}




@media screen and (min-width:600px) and (max-width:1400px) {
    .admin-payroll-heading h2 {}
}

@media screen and (min-width:300px) and (max-width:600px) {
    .admin-payroll-heading h2 {
        font-size: 16px;
    }
}