.audit-task-sub-container {
    margin-left: 20px;
}

.audit-task-sub-container-margin0 {
    margin-left: 0px;
}



.audit-task-sub-container h4 {
    color: var(--primaryColor);
    text-decoration: underline;
    margin-bottom: 5px !important;
}

.audit-task-sub-container h4::after {
    content: ':';
}

.audit-task-main-container {
    padding-top: 22px !important
}

.audit-task-main-container-padding0 {
    padding-top: 0px !important
}



.task-item {
    padding: 0px 22px 0px 12px !important;
}

.task-item-padding-right0 {
    padding: 0px 22px 0px 0px !important;
}

.task-sub-item-container {
    height: 28px !important
}


.task-list-item {
    font-size: var(--medium-font) !important;
    color: var(--primaryColor);
}

.task-details-view-button {
    border: 1px solid red;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px
}

.task-sub-item-container .ant-timeline-item-head {
    margin-top: 3px !important;
}

.task-list-item .ant-timeline-item-content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.view-timeline-container {
    margin-left: 12px !important;
    margin-top: 8px !important;
}

.view-timeline-container .ant-timeline-item {
    padding-bottom: 8px !important;
}