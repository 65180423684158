.card-title {
    height: 35px;
    padding: 0px 8px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--tertiarycolor);
    background-color: var(--primaryColor);
}

.card-title-wrapper {
    flex-basis: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.single-line-text {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}