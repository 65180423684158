.user-salary-header-sub-container {
    display: flex;
    flex-basis: 20px;
    justify-content: center;
    align-items: center;
}

.salaryslip-sub-container {
    height: 100% !important;
}

.search-container {
    width: 90px !important;

}

.salaryslip-sub-container h4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25px;
    font-weight: 600;
}

.user-salary-grid-details-container {
    height: calc(100% - 25px);
    width: 100%;
}

.salary-sub-container {
    overflow-y: auto;
    height: calc(100% - 35px);
    padding-top: 0px;
    /* border: 1px solid red */
}

@media screen and (min-width:600px) and (max-width:699px) {
    .salaryslip-sub-container h4 {
        font-size: 12px;
    }
}

@media screen and (min-width:300px) and (max-width:599px) {
    .salaryslip-sub-container h4 {
        font-size: 12px;
    }
}