.create-leave-heading-container {
    padding: 1.25%;
    display: flex;
    justify-content: space-between;
    gap: 2%;
    height: 15%
}

.create-leave-heading-container h3 {
    padding: 2px;
}

.create-leave-close-button {
    font-size: 20px
}

.create-leave-sub-container {
    height: 85%
}

.create-leave-form-container {
    height: 100%
}

.create-leave-field-container {
    height: 85%;
    margin-right: 20px
}

.create-leave-field-sub-container {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column
}

.create-leave-button-container {
    height: 15%;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px
}