.card-item {
    display: flex;
    justify-content: space-between;
    padding-right: 4px;
    gap: 20px !important;
}

.status-circle-yellow {
    color: var(--secondaryColor)
}

.status-circle-red {
    color: var(--dangerColor)
}


.status-outline-redo {
    border: 1px solid var(--secondaryColor);
    border-radius: 10px;
    color: var(--secondaryColor)
}

.work-from-home-icon {
    color: var(--successColor);
    font-size: 20px;
    border: 1px solid var(--successColor);
    border-radius: 10px;
    padding: 2px;
    font-weight: 900;
}


.demo-date-container {
    color: red
}