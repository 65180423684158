.admin-holiday-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.admin-holiday-sub-container {
  height: calc(100% - 30px);
  padding-top: 5px;
}

.holiday-list-sub-container {
  margin: 0;
  display: flex;
  width: 100%;
  padding-left: 25%;
  padding-Right: 25%;
  justify-content: center;
}

.holiday-list-sub-container li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.admin-holiday-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.holiday-sub-heading-container {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.holiday-table-wrapper {
  height: 100%;
  width: 100%;
}

.table-wrapper::-webkit-scrollbar {
  height: 8px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: var(--darkblue);
  border-radius: 40px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 40px;
}

.table-wrapper table {
  border-collapse: collapse;
}

.table-wrapper table th,
.table-wrapper table td {
  padding: 10px;
  min-width: 75px;
}

.table-wrapper table th {
  color: var(--white);
  background: var(--darkblue);
}

.table-wrapper table tbody tr:nth-of-type(even)>* {
  background: var(--lightblue);
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

.holiday-nodata-container {
  display: flex;
  margin-top: 85px;
}

.holiday-nodata-alert-icon {
  color: var(--dangerColor)
}

.holiday-nodata-container>div {
  margin-top: 7px;
}

.holiday-form-container {
  height: 25%;
}


@media screen and (min-width:300px) and (max-width:599px) {
  .admin-holiday-heading h2 {
    font-size: 18px;
  }
}