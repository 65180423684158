.timeline-heading {
    font-size: var(--small-font) !important;
    font-weight: 600;
    margin-bottom: 10px;
}



/* .main-timeline-container .ant-timeline-item {
    height: 46px !important;
} */