.time-count-sub-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px !important;
}

.dashboard-sub-container-wrapper {
    height: 100%;
    overflow-x: hidden;
    padding-right: 10px
}

.main-grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-more-container {
    font-size: 15px;
    cursor: pointer
}

.time-count-main-container {
    height: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.time-count-button-container {
    display: flex;
    justify-content: space-evenly;
    gap: 5px
}




.project-container {
    height: 220px;
    padding: 0px 0px 5px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

.project-container-heading {
    color: var(--tertiarycolor);
    background-color: var(--primaryColor);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-sub-conatiner {
    height: calc(100% - 35px);
    overflow-y: auto;
    padding: 0px 5px;
}

.project-sub-item-conatiner {
    display: flex;
    justify-content: space-between;
    padding-left: 4px
}

.project-sub-conatiner::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.project-sub-conatiner::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.project-sub-conatiner::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.project-sub-conatiner::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.dashboard-grid-container {
    height: 100px;
}

.clock-in,
.clocked-out button {
    background-color: var(--primaryColor);
}


@media (max-width: 575px) {

    /* .time-tracker h1 {
        font-size: 100%;
    } */

    .clock-in,
    .clocked-out button {
        padding: 5px 5px;

    }

}

.status {
    margin: 0px 0;
}

.button-width {
    width: '20px' !important
}

.clocked-in,
.clocked-out,
.breaked-in,
.breaked-out {
    margin-top: 10px !important;
}

.clock-in-out {
    padding-bottom: 10px;

}

.clock-out-button {
    background-color: red;
}

.clocked-in button:disabled {
    background-color: rgb(191, 111, 111);
}

button {
    min-height: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.break-button {
    margin-top: 10px;
}




.time-count-container {
    margin: 0px 0px;
}



.dashboard-details-container:hover {
    overflow-y: auto;
}

.dashboard-sub-container-wrapper::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.dashboard-sub-container-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.dashboard-sub-container-wrapper::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}