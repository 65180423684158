.reason-main-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    cursor: pointer;
}


.reason-container {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    width: 100%
}

.task-container {
    height: 100%;
    overflow: auto;
}

.task-sub-ag-grid-container {
    height: calc(100% - 38px) !important
}

.task-update-button-container {
    height: 38px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 4px
}

.calendar-container .react-calendar__navigation {
    display: flex;
    /* justify-content: center; */
}

.react-calendar__navigation button {
    flex-grow: 0;
}


.completion-date-picker-container .MuiFormControl-root {
    height: 35px;
}

.completion-date-picker-container .MuiInputBase-input {
    margin-top: 7px !important;
    margin-left: 13px !important;
}