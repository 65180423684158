.sub-container-wrapper {
    height: 100%;
    overflow-x: hidden;
    padding-right: 10px;
}

.main-card-container {
    height: 220px;
    box-shadow: inset 0 -2px 6px 0 rgba(10, 37, 64, .35);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgb(255, 255, 255);
    padding-bottom: 8px;
}



.card-sub-body:hover {
    overflow-y: auto;
}

.sub-container-wrapper::-webkit-scrollbar,
.card-sub-body::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.sub-container-wrapper::-webkit-scrollbar-track,
.card-sub-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.sub-container-wrapper::-webkit-scrollbar-thumb,
.card-sub-body::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.sub-container-wrapper::-webkit-scrollbar-thumb:hover,
.card-sub-body::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.card-body {
    height: calc(100% - 35px) !important;

}

.card-sub-body {
    padding: 2px 0px 0px 0px;
    margin: 0px 4px 10px 10px !important;
    overflow: hidden;
    height: 100% !important;
}