.status-container {
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px !important;
    font-size: var(--extra-small-font) !important;
}

.status-update-date-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14px !important;
    font-size: var(--more-extra-small-font) !important;
    border-radius: 4px;
    background-color: var(--secondaryColor);
    padding: 3px 3px 3px 3px;
}

.status-update-start-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14px !important;
    color: var(--tertiarycolor);
    font-size: var(--more-extra-small-font) !important;
    border-radius: 4px;
    background-color: var(--primaryColor1);
    padding: 3px 3px 3px 3px;
}

.status-update-main-container {
    display: flex;
    justify-content: flex-end;
    align-items: center
}


.timeline-reason {
    font-size: var(--extra-small-font) !important;
}

.timeline-completionDate-container .ant-timeline-item-head {
    border-radius: 0;
    border-color: var(--purpleColor);
}

.timeline-completionDate-container {
    padding-bottom: 8px !important;
}

.timeline-start-date-container .ant-timeline-item-head {
    border-color: var(--primaryColor1);
}

.timeline-start-date-container {
    padding-bottom: 8px !important;
}